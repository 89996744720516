import jwt from '@/scripts/jwt';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import qs from 'qs';

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  paramsSerializer: params => qs.stringify(params),
});

api.interceptors.request.use(function (config) {
  if (!config.headers.Authorization) config.headers.Authorization = jwt.header();
  return config;
});

api.interceptors.response.use(
  response => response,
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('logOut', { passthrough: true, toast: 'expired' });
      } else {
        store.commit('toast/error', error);
        if (error.response.status === 403 || error.response.status === 404) router.push('/');
      }
    }

    return Promise.reject(error);
  }
);

export default api;
