<script>
import VideoButton from '@/content/components/buttons/VideoButton';
import AdminModal from '@/admin/components/AdminModal';
import Bookmarks from '@/global/components/Bookmarks';
import Vimeo from '@/global/components/Vimeo2';
import Brand from '@/global/components/Brand';
import Link from '@/global/components/Link2';
import { mapGetters, mapState } from 'vuex';

const years = ['EYFS', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6'];

const products = [
  {
    slug: 'pe',
    years: [0, 1, 2, 3, 4, 5, 6],
    intro: 'The foundation of your PE curriculum, providing a full year of interactive lessons.',
    title: 'core real PE',
    video: '948786064',
  },
  {
    slug: 'gym',
    years: [0, 1, 2, 3, 4, 5, 6],
    intro: 'Two terms of interactive gymnastics lessons, incorporating floor work, apparatus and sequence development.',
    title: 'real gym',
    video: '946993169',
  },
  {
    slug: 'dance',
    years: [0, 1, 2, 3, 4, 5, 6],
    intro: 'A term of interactive dance lessons, putting children at the heart of their own dance development.',
    title: 'real dance',
    video: '946127577',
  },
  {
    slug: 'foundations',
    years: [0],
    ksYears: { themes: [0] },
    intro: 'Interactive EYFS programme that uses physical activity as the driver for learning.',
    title: 'real PE foundations',
    video: '946111935',
  },
  {
    slug: 'play',
    years: [0, 1, 2],
    intro:
      'Interactive nurture programme to build positive relationships with physical activity for children and families.',
    title: 'real play',
    video: '944418695',
  },
  {
    slug: 'leaders',
    years: [5, 6],
    intro: 'Key Stage 2 leadership programme, teaching essential leadership skills using physical activity.',
    uri: '/leaders',
    title: 'real leaders',
    video: '944390161',
  },
  {
    slug: 'home',
    years: [0, 1, 2, 3, 4, 5, 6],
    ksYears: { themes: [0, 1, 2], funs: [3, 4, 5, 6] },
    intro: 'Bitesize physical activities to use throughout the school day, linking directly to core real PE skills.',
    title: 'real PE daily',
    video: '945863886',
  },
  {
    slug: 'assessment',
    years: [0, 1, 2, 3, 4, 5, 6],
    intro: 'Access your real PE assessment wheel. For assessment set-up and other guidance, click Support.',
    title: 'real PE assessment',
    video: '941639346',
    support: 'support/real-pe-assessment',
  },
];

export default {
  components: { Brand, Bookmarks, Link, AdminModal, Vimeo, VideoButton },
  data: () => ({ selected: null, vid: {} }),

  computed: {
    access() {
      return this.$store.state.account.access;
    },

    years() {
      const allow = this.products.flatMap(product => product.years || []);
      return years.map((label, i) => ({ label, num: i })).filter(year => allow.includes(year.num));
    },

    products() {
      return products.map(({ slug, uri, ...product }) => {
        const isAssessment = slug === 'assessment';
        const accessYears = (this.access[slug] || []).flatMap(i => (product.ksYears || [])[i] || i);
        const access = this.isCreate || !!accessYears.length || (isAssessment && this.wheelUrl);
        if (isAssessment) uri = this.wheelUrl;

        return {
          ...product,
          slug,
          access,
          newTab: isAssessment,
          ...(access && { uri, on: !uri ? { click: () => this.open(slug) } : {} }),
        };
      });
    },

    products2() {
      return this.products.map(product => ({
        ...product,
        // active: product.access,
        active: this.selected === null || product.years.includes(this.selected),
      }));
    },

    ...mapState(['dashboard']),
    ...mapGetters('account', ['wheelUrl']),
    ...mapGetters(['forumUrl', 'lmsUrl', 'pfwUrl']),
    ...mapGetters('user', ['isCreate', 'isRealLead', 'isHome']),
  },

  methods: {
    select({ num }) {
      this.selected = this.selected === num ? null : num;
    },
    open(slug) {
      this.$store.dispatch('menu/select', { slug, select: this.selected });
    },
  },

  mounted() {
    if (this.isHome) this.$router.replace('/home');
  },
};
</script>

<template>
  <div class="relative">
    <div class="container my-10">
      <div class="mb-10">
        <h1 class="head-4xl leading-none font-bliss mb-2">real PE Curriculum</h1>
        <p>
          Access is available to all programmes to support your curricular, extra-curricular and enrichment offer for PE
        </p>
      </div>

      <div class="flex justify-between items-end pb-3">
        <div class="">
          <div class="text-sm uppercase text-blank font-bold tracking-widest">Filter by year:</div>
          <div class="flex -mx-1">
            <div v-for="year in years" :key="year.num" class="">
              <button :class="{ active: year.num === selected }" class="nm1 bg-white" @click="select(year)">
                {{ year.label }}
              </button>
            </div>
          </div>
        </div>
        <div class="flex-grow"></div>
        <RouterLink to="/support" class="nm1 bg-white">Support</RouterLink>
        <Bookmarks btn-class="bg-white" class="relative z-30" />
      </div>

      <div class="flex flex-wrap -mx-2">
        <div
          v-for="product in products2"
          :key="product.slug"
          :id="`dash-menu-${product.slug}`"
          :class="{ 'opacity-25': !product.active }"
          class="w-1/3 lg:w-1/4 px-2 pb-4 trns-opacity relative"
        >
          <Link
            :uri="product.uri"
            :new-tab="product.newTab"
            :class="product.access ? `border-${product.slug}` : ''"
            class="dash-block dash-block-btn pb-16"
            v-on="product.on"
          >
            <Brand :slug="product.slug" :class="{ 'opacity-50 grayscale': !product.access }" class="mb-3 h-16" />
            <div class="text-sm mb-1">{{ product.intro }}</div>
            <svg v-if="product.access" class="w-8 h-8 fill-grey print:hidden absolute top-0 right-0 m-4">
              <use xlink:href="#arrow" />
            </svg>
          </Link>

          <div class="absolute bottom-0 left-0 ml-5 mb-8 flex">
            <button class="dbv" @click="vid = product">
              <span class="mr-2">Guide</span>
              <svg class="dbv-icon"><use xlink:href="#video" /></svg>
            </button>
            <RouterLink v-if="product.support" :to="product.support" class="db1">Support</RouterLink>
          </div>
        </div>
      </div>
    </div>

    <AdminModal :active="!!vid.video" :title="vid.title" wide @close="vid = {}">
      <Vimeo :vid="vid.video" class="-m-2 rounded-br-xl" />
    </AdminModal>

    <!-- TW: border-assessment -->
  </div>
</template>
