import jwt_decode from 'jwt-decode';

const handle = 'real-token';

const get = () => localStorage.getItem(handle);

const set = token => localStorage.setItem(handle, token);

const remove = () => localStorage.removeItem(handle);

const header = () => {
  const token = get();
  return token ? `Bearer ${token}` : '';
};

const decode = token => {
  token = token || get();

  if (!token) return;

  try {
    return jwt_decode(token);
  } catch (error) {
    console.error(error.message);
  }
};

export default { get, set, remove, header, decode };
