<script>
import Disc from '@/content/components/partials/Disc';

export default {
  components: { Disc },
  props: ['lesson'],

  computed: {
    product() {
      return this.$route.params.product;
    },
    part() {
      return this.$route.params.part;
    },

    primary() {
      if (!this.lesson.parts) return;
      const index = this.lesson.altParts.findIndex(part => part && part.slug === this.part);
      return this.lesson.parts[index];
    },
    alternative() {
      if (!this.lesson.parts) return;
      const index = this.lesson.parts.findIndex(part => part && part.slug === this.part);
      const part = this.lesson.altParts[index];
      if (part?.type === 'story') return; // dont show story as alternative
      return part;
    },

    activity() {
      return this.primary ? { ...this.primary, alt: 'Original' } : { ...this.alternative, alt: 'Alternative' };
    },
    uri() {
      return `${this.lesson.uri}/${this.activity.type}/${this.activity.slug}`;
    },
  },
};
</script>

<template>
  <RouterLink v-if="activity.id" :to="uri" class="flex items-center">
    <Disc :asset="activity.previewImage" :colour="product" class="w-16 lg:w-20 z-10" />
    <div :class="`border-${product}-shade`" class="border-2 rounded-xl -ml-4 py-1 pr-3 pl-6">
      <div class="text-sm leading-tight text-center font-medium w-16">{{ activity.alt }} activity</div>
    </div>
  </RouterLink>
</template>
