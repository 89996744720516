<script>
import ToastList from '@/global/components/ToastList';
import Foot from '@/global/components/Foot';
import jwt from '@/scripts/jwt';

export default {
  components: { ToastList, Foot },

  metaInfo() {
    return { titleTemplate: t => (t ? `${t} | real PE` : 'real PE') };
  },

  mounted() {
    if (jwt.decode()?.id === 3) return;

    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66509e289a809f19fb34a993/1hulejk2l';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  },
};
</script>

<template>
  <div id="jasmine" class="flex flex-col min-h-screen">
    <ToastList />
    <RouterView class="flex-grow" />
    <Foot />
  </div>
</template>
